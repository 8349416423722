import React from 'react';
import {Link} from "react-router-dom";
import {filterCategorical} from "../HelperFunction";
import '../App.css';
import TinyASF from "./TinyASF";
import CategoricalPlotSmall from "./CategoricalPlotSmall";
import Plot from "react-plotly.js";
import NumericalPlotSmall from "./NumericalPlotSmall";
import Footer from "./Footer";
import {colorChartBackground} from "./HelperComponents"

var {jStat} = require('jstat')
var Statistics = require('statistics.js');

class AttributeOverview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.location.state.data,
            overallResult: this.props.location.state.overallResult,
            selectedAttributes: this.props.location.state.selectedAttributes,
            options: this.props.location.state.options,
            categorical: this.props.location.state.categorical,
            numerical: this.props.location.state.numerical,
            includeCorrelation: true, //Change this variable in order to show or hide the correlation plots
            categoricalCorrelation: [],
            numericalCorrelation: [],

        };
        this.removeASF = this.removeASF.bind(this)
        this.removeAttributeFromListCategorical = this.removeAttributeFromListCategorical.bind(this);
        this.removeAttributeFromListNumerical = this.removeAttributeFromListNumerical.bind(this);
    }

    /**
     * Life-cycle method that initializes the correlation plots and calculates the correlation
     */
    componentWillMount() {
        let categoricalCorrelation = this.calculateStatisticsCategorical();
        let numericalCorrelation = this.calculateStatisticsNumerical()

        this.setState({categoricalCorrelation: categoricalCorrelation, numericalCorrelation: numericalCorrelation})
    }

    /**
     * Removes an ASF and the associated scores
     * @param String The attribute where the ASF should be removed for
     */
    removeASF(attribute) {
        let result = this.state.overallResult;
        let removed, rest;
        let removedArray;

        //Remove the scores for a given attribute
        removedArray = [].concat(result.map(element => {
            ({[attribute]: removed, ...rest} = element);
            return rest;
        }))

        //Remove the attribute from the list of selected attributes
        let selectedAttributes = this.state.selectedAttributes;
        const shortenedArray = selectedAttributes.filter(element => attribute !== element);

        //Remove the attribute from the options
        let options = this.state.options;
        delete options[attribute]
        this.setState({overallResult: removedArray, selectedAttributes: shortenedArray, options: options})
    }

    /**
     * Removes a categorical attribute completely such that is is not shown on the overview anymore and no ASF can
     * be added for it
     * @param String The attribute that should be removed
     */
    removeAttributeFromListCategorical(attribute) {
        let categorical = this.state.categorical.filter(element => attribute !== element);

        let result = this.state.data;
        let removed, rest;
        let removedArray;
        removedArray = [].concat(result.map(element => {
            ({[attribute]: removed, ...rest} = element);
            return rest;
        }))

        this.setState({categorical: categorical, data: removedArray,}, () => this.removeASF(attribute));
    }

    /**
     * Removes a numerical attribute completely such that is is not shown on the overview anymore and no ASF can
     * be added for it
     * @param String The attribute that should be removed
     */
    removeAttributeFromListNumerical(key) {
        let numerical = this.state.numerical.filter(element => key !== element);

        let result = this.state.data;
        let removed, rest;
        let removedArray;
        removedArray = [].concat(result.map(element => {
            ({[key]: removed, ...rest} = element);
            return rest;
        }))

        this.setState({numerical: numerical, data: removedArray,}, () => this.removeASF(key));
    }

    /**
     * Calculate the pearson correlation coefficient for all numerical attributes
     */
    calculateStatisticsNumerical() {
        let numerical = this.state.numerical;
        let overAllResultNumerical = [];

        //Loop through all numerical attributes
        numerical.forEach(numA => {
            let result = []
            //Loop through all combinations of two numerical attributes
            numerical.forEach(numB => {

                if (numA === numB) {
                    //If both numerical attributes are the same, the correlation must be one
                    result.push(1)
                } else {
                    //If the attributes are different, we use the statistics package to calculate the correlation
                    var bodyVars = {
                        [numA]: 'metric',
                        [numB]: 'metric'
                    };

                    var stats = new Statistics(this.state.data, bodyVars);
                    var r = stats.correlationCoefficient(numA, numB);

                    result.push(r.correlationCoefficient)
                }
            })
            overAllResultNumerical.push(result)
        })
        return overAllResultNumerical
    }

    /**
     * Calculate the chi square test of independence for all categorical attributes
     */
    calculateStatisticsCategorical() {
        let categorical = this.state.categorical;

        let overAll = []
        categorical.forEach(catA => {
            let row = []
            categorical.forEach(catB => {
                if (catA === catB) {
                    //If both categories are the same, then the correlation must be one
                    row.push(1);
                } else {
                    //Else, we calculate the correlation
                    let chiSquare = 0;

                    //Get all unique values for both attributes
                    let aDistinct = [...new Set(this.state.data.map(item => item[catA]))];
                    let bDistinct = [...new Set(this.state.data.map(item => item[catB]))];
                    ;

                    aDistinct.forEach(distinctA => {
                        bDistinct.forEach(distinctB => {

                            //Count how many observations we find in the data set for a given pair of attribute values
                            let observed = this.state.data.filter(item => item[catA] === distinctA && item[catB] == distinctB).length

                            //Count how many observations we expect in the data set for a given pair of attribute values
                            let expectedValue = (this.state.data.filter(item => item[catA] === distinctA).length * this.state.data.filter(item => item[catB] === distinctB).length) / this.state.data.length

                            //Calculate the difference
                            let result = (observed - expectedValue) * (observed - expectedValue) / expectedValue;
                            chiSquare = chiSquare + result;

                        })
                    })
                    let value = jStat.chisquare.pdf(chiSquare, (aDistinct.length - 1) * (bDistinct.length - 1))
                    console.log("Value", value)
                    row.push(value)
                }
            })
            overAll.push(row)
        })
        return overAll;
    }

    render() {
        let correlationPlots = <div></div>

        if (this.state.includeCorrelation) {
            let categoricalCorrelationPlot, numericalCorrelationPlot;

            numericalCorrelationPlot = <Plot
                data={[{
                    z: this.state.numericalCorrelation, type: 'heatmap', y: this.state.numerical,
                    x: this.state.numerical, hoverinfo: "text",
                    zmin: -1, zmax: 1,
                }]}
                layout={{
                    margin: {
                        l: 200,
                        b: 100
                    },
                    font:{
                        size: 18
                    },
                    xaxis: {autorange: 'reversed'},
                    width: 900,
                    height: 500,
                    title: 'Correlation between Numerical Attributes (Pearson Correlation)',
                    paper_bgcolor: colorChartBackground, plot_bgcolor: colorChartBackground,
                    display: 'inline',
                }}
            />;

            categoricalCorrelationPlot = <Plot
                data={[{
                    z: this.state.categoricalCorrelation, type: 'heatmap', y: this.state.categorical,
                    x: this.state.categorical, hoverinfo: "text",  colorscale: 'OrRd',
                    zmin: 0, zmax: 1,
                }]}
                layout={{
                    margin: {
                        l: 200,
                        b: 100
                    },
                    font:{
                        size: 18
                    },
                    xaxis: {autorange: 'reversed'},
                    width: 900,
                    height: 500,
                    title: 'Correlation between Categorical Attributes (Chi Square)',
                    paper_bgcolor: colorChartBackground, plot_bgcolor: colorChartBackground,
                    display: 'inline',
                }}
            />

            correlationPlots = <>
                <div>
                    <p className='text'>These plots show the correlations between all attributes:</p>
                </div>
                <div style={{paddingBottom: '20px'}}>
                    <div style={{paddingLeft: '50px', marginBottom: '50px'}}>
                        {categoricalCorrelationPlot}
                    </div>
                    <div style={{paddingLeft: '50px',}}>
                        {numericalCorrelationPlot}
                    </div>
                </div>
            </>
        }

        return (
            <div>
                <div className='largeHeader'><h1 style={{display: 'inline'}}>Overview of all attributes</h1>
                    <div style={{display: 'inline', float: 'right', marginRight: '5%'}}>

                        {this.state.selectedAttributes.length > 1 ?
                            <Link to={{
                                pathname: '/weighting',
                                state: {
                                    data: this.state.data,
                                    overallResult: this.state.overallResult,
                                    selectedAttributes: this.state.selectedAttributes,
                                    options: this.state.options,
                                    categorical: this.state.categorical,
                                    numerical: this.state.numerical,
                                }
                            }}>
                                <button className='nextButton button' type="button">Next</button>
                            </Link>
                            : <></>}

                    </div>
                </div>
                <p className='text'>
                    Please create attribute scoring functions for all attributes of interest. You can also modify
                    existing scoring functions.
                    In order to proceed, you need to define at least two Attribute Scoring Functions.
                    When you are ready to see the result, click on the next button.
                </p>


                <div><h2 className='smallHeader'>Categorical Attributes</h2></div>

                <div style={{paddingLeft: '2%'}}>
                    {this.state.categorical.map((key) => (
                        <>
                            <div className='smallChartCategorical'>
                                <div style={{marginTop: '5%'}}>
                                    <div style={{paddingLeft: '7%', marginTop: '5%', display: 'inline'}}>{key}</div>
                                    <button style={{float: 'right', marginRight: '9%', marginBottom: '5%', display: 'inline'}}
                                            key={'remove'} onClick={() => {
                                        this.removeAttributeFromListCategorical(key)
                                    }}><img src={process.env.PUBLIC_URL + 'images/icons8-remove-30.png'}
                                            width={'20px'}/>
                                    </button>
                                </div>
                                <div style={{paddingLeft: '2%'}}>
                                    <CategoricalPlotSmall key={key}
                                                          data={filterCategorical(key, this.state.data)}
                                                          name={key}/>
                                </div>
                                {this.state.selectedAttributes.find(element => element === key) ?
                                    <>
                                        <p className='smallText'>Missing
                                            Values: {this.state.data.filter(element => element[key] === null).length}</p>
                                        <div style={{paddingLeft: '8%'}}>
                                            <Link to={{
                                                pathname: '/selectedAttributesCategorical',
                                                state: {
                                                    data: this.state.data,
                                                    selected: key,
                                                    isCategorical: true,
                                                    overallResult: this.state.overallResult,
                                                    selectedAttributes: this.state.selectedAttributes,
                                                    options: this.state.options,
                                                    categorical: this.state.categorical,
                                                    numerical: this.state.numerical,
                                                }
                                            }}>
                                                <button className='modifyButton' type="button">Modify</button>
                                            </Link>
                                            <button style={{float: 'right', marginRight: '9%'}}
                                                    className='remove-Button' key={'remove'} onClick={() => {
                                                this.removeASF(key)
                                            }}>Remove
                                            </button>
                                        </div>
                                    </> :
                                    <>
                                        <p className='smallText'>Missing
                                            Values: {this.state.data.filter(element => element[key] === null).length}</p>
                                        <div style={{paddingLeft: '30%'}}>
                                            <Link to={{
                                                pathname: '/selectedAttributesCategorical',
                                                state: {
                                                    data: this.state.data,
                                                    selected: key,
                                                    isCategorical: true,
                                                    overallResult: this.state.overallResult,
                                                    selectedAttributes: this.state.selectedAttributes,
                                                    options: this.state.options,
                                                    categorical: this.state.categorical,
                                                    numerical: this.state.numerical,
                                                }
                                            }}>
                                                <button className='createButton button' type="button">Add</button>
                                            </Link></div>
                                    </>
                                }
                            </div>
                        </>
                    ))}
                </div>

                <div><h2 className='smallHeader'>Numerical Attributes</h2></div>
                <div style={{paddingLeft: '2%'}}>
                    {this.state.numerical.map((key) => (
                        <div className='smallChart'>
                            <div style={{marginTop: '5%'}}>
                                <div style={{paddingLeft: '7%', marginTop: '5%', display: 'inline'}}>{key}</div>
                                <button style={{float: 'right', marginRight: '9%', marginBottom: '5%', display: 'inline'}} key={'remove'}
                                        onClick={() => {
                                            this.removeAttributeFromListNumerical(key)
                                        }}><img src={process.env.PUBLIC_URL + 'images/icons8-remove-30.png'}
                                                width={'20px'}/>
                                </button>
                            </div>
                            <div style={{paddingLeft: '2%'}}>
                                <NumericalPlotSmall key={key} data={this.state.data.map(object => object[key])}
                                                    name={key}/>
                            </div>
                            {this.state.selectedAttributes.find(element => element === key) ?

                                <>
                                    <div style={{display: 'inline', marginLeft: '17%'}}>
                                        <TinyASF options={this.state.options[key]}/>
                                    </div>
                                    <div style={{paddingLeft: '8%', paddingTop: '10px', paddingBottom: '0px'}}>
                                        <Link to={{
                                            pathname: '/selectedAttributesNumerical',
                                            state: {
                                                data: this.state.data,
                                                selected: key,
                                                isCategorical: true,
                                                overallResult: this.state.overallResult,
                                                selectedAttributes: this.state.selectedAttributes,
                                                options: this.state.options,
                                                categorical: this.state.categorical,
                                                numerical: this.state.numerical,
                                            }
                                        }}>
                                            <button className='modifyButton' type="button">Modify</button>
                                        </Link>
                                        <button style={{float: 'right', marginRight: '9%'}} className='remove-Button'
                                                key={'remove'} onClick={() => {
                                            this.removeASF(key)
                                        }}>Remove
                                        </button>
                                    </div>
                                </> :
                                <>
                                    <p className='smallText' style={{paddingBottom: '15px'}}>Missing
                                        Values: {this.state.data.filter(element => isNaN(element[key])).length}</p>
                                    <div style={{paddingLeft: '30%', paddingTop: '10%', marginTop: '14%'}}>
                                        <Link to={{
                                            pathname: '/selectedAttributesNumerical',
                                            state: {
                                                data: this.state.data,
                                                selected: key,
                                                isCategorical: true,
                                                overallResult: this.state.overallResult,
                                                selectedAttributes: this.state.selectedAttributes,
                                                options: this.state.options,
                                                categorical: this.state.categorical,
                                                numerical: this.state.numerical,
                                            }
                                        }}>
                                            <button className='createButton button' type="button">Add</button>
                                        </Link></div>
                                </>
                            }
                        </div>
                    ))}
                </div>
                {correlationPlots}
            </div>
        );
    }
}

export default AttributeOverview;